
    window.isJson = function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    window.displayLoader = function (){
        $("#portail-loader").show();
    }

    window.hideLoader = function (){
        $("#portail-loader").remove();
    }