import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;
window.jquery = jQuery;

import  createPopper  from '@popperjs/core';

//import './bootstrap';
require('bootstrap');
import './base';
window.moment = require('moment');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();


//import Chart from 'chart.js/auto';
const { Chart } = await import('chart.js');
import { getRelativePosition } from 'chart.js/helpers';
//window.Chart = Chart;

//
require('datatables.net-dt');
require('datatables.net-buttons-dt');
require('datatables.net-responsive-dt');
require('datatables.net-buttons/js/buttons.colVis.mjs');
require('datatables.net-buttons/js/buttons.html5.mjs');
/*import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
window.DataTable = DataTable;
window.dt = DataTable;*/
//window.DataTable.ext.errMode = 'none';
